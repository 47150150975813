.App {
  height: 100vh;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Gilroy-Light;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

h1 {
  font-family: 'Gilroy-Medium';
  font-size: 100px;
  line-height: 117px;
  text-align: center;
  margin-bottom: 99px;
}

h3 {
  font-family: Gilroy-Bold;
  font-size: 46px;
  line-height: 54px;
}

h5 {
  font-family: Gilroy-Medium;
  font-size: 25px;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}

section {
  position: relative;
  height: calc(100% - 142px);
  padding: 71px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
}

.gray {
  background: #F5F5F5;
}

.mid {
  width: calc(50% - 71px);
}

.center-horizontal {
  justify-content: start;
  align-items: center;
  height: auto;
}

img {
  max-width: 100%;
}

@keyframes MoveUpDown {
  0%, 100% {
    bottom: 71px;
  }
  50% {
    bottom: 30px;
  }
}

.down-icon {
  position: absolute;
  bottom: 71px;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto;
  width: 51px;
  animation: MoveUpDown 1.5s ease-in-out infinite;
}

.down-icon:hover{
  cursor: pointer;
}

p + p {
  margin-bottom: 50px;
}


@media only screen and (max-width: 950px) {
  body {
    font-size: 18px;
    line-height: 22px;
  }

  h1 {
    font-size: 60px;
    line-height: 70px;
    text-align: center;
    margin-bottom: 60px;
  }

  h3 {
    font-size: 28px;
    line-height: 32px;
  }

  h5 {
    font-size: 15px;
    line-height: 17px;
  }

  section {
    padding: 50px;
  }

  .mid {
    width: 100%;
  }

  .down-icon {
    width: 30px;
  }
}