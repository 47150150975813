@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilroy-Bold'), url(./assets/fonts/Gilroy-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Light';
  src: local('Gilroy-Light'), url(./assets/fonts/Gilroy-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilroy-Medium'), url(./assets/fonts/Gilroy-Medium.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}
